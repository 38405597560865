




































import { Area, Button, Cell, CellGroup, Checkbox, CheckboxGroup, Dialog, Field, Image, Popup } from "vant";
import wx from "weixin-js-sdk";
import areaList from "@/assets/js/area";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    [Image.name]: Image,
    [Area.name]: Area,
    [Popup.name]: Popup,
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    return {
      name: "",
      userId: 0,
      userName: "",
      userPhone: "",
      managerId: 0,
      managerName: "",
      managerPhone: "",
      servicePhone: "",
      number: "" as number | string,
      province: "",
      city: "",
      area: "",
      services: [3] as number[],
      dialog: {
        show: false,
        keyword: ""
      },
      showAreaPicker: false,
      areaList: areaList
    };
  },
  created() {
    this.loadUser();
  },
  computed: {
    service(): number {
      return this.services.reduce((a, b) => a + b, 0);
    },
    areaMsg(): string {
      return [this.province, this.city, this.area].join(" ");
    },
    account(): string {
      return this.userName + "(" + this.userPhone + ")";
    },
    manager(): string {
      return this.managerName + "(" + this.managerPhone + ")";
    },
    isApplyReady(): boolean {
      return Boolean(this.name && this.servicePhone);
      // && this.number && this.province && this.city && this.area && this.service;
    }
  },
  methods: {
    apply() {
      const data = {
        name: this.name,
        managerId: this.managerId,
        servicePhone: this.servicePhone,
        number: this.number,
        service: this.service,
        province: this.province,
        city: this.city,
        area: this.area
      };
      this.$axios.post("/api/manage/business/apply", data).then(() => {
        Dialog.alert({ message: "您的申请已提交!" }).then(() => {
          wx.miniProgram.navigateBack({ delta: 1 });
        });
      });
    },
    onPickArea(areaInfos: { name: string; code: string }[]) {
      this.province = areaInfos[0].name;
      this.city = areaInfos[1].name;
      this.area = areaInfos[2].name;
      this.showAreaPicker = false;
    },
    loadUser() {
      this.$axios
        .post("/api/manage/getUserInfo")
        .then(e => {
          const data = e.data.data;
          if (!data.isAuth) {
            Dialog.alert({
              message: "请先实名"
            }).then(() => {
              wx.miniProgram.navigateBack({ delta: 1 });
            });
          }
          this.userId = data.id;
          this.userName = data.name;
          this.userPhone = data.phone;
          this.managerId = data.id;
          this.managerName = data.name;
          this.managerPhone = data.phone;
        })
        .catch(() => {
          Dialog.alert({
            message: "请先登录"
          }).then(() => {
            wx.miniProgram.navigateBack({ delta: 1 });
          });
        });
    },
    findUser() {
      this.dialog.show = false;
      this.$axios.post("/api/manage/findUser", { keyword: this.dialog.keyword }).then(e => {
        const data = e.data.data;
        if (!data.name) {
          Dialog.alert({ message: "用户未实名,请先在个人中心实名" });
          return;
        }
        this.managerId = data.id;
        this.managerName = data.name;
        this.managerPhone = data.phone;
      });
    }
  }
});
